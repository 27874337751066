import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 定义路由的规则 --- 将组件映射到路由
const routes = [
  {
    path: '/', redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    components: {
      default: () => import(/* webpackChunkName: "base" */ '../views/login/index.vue')
    }
  },
  {
    path: '/index',
    name: 'index',
    components: {
      default: () => import(/* webpackChunkName: "base" */ '../views/index/index.vue')
    }
  },
  {
    path: '/adminindexx',
    name: 'adminindexx',
    components: {
      default: () => import(/* webpackChunkName: "base" */ '../views/adminindexx/index.vue')
    }
  },
  {
    path: '/list',
    name: 'list',
    components: {
      default: () => import(/* webpackChunkName: "base" */ '../views/list/index.vue')
    }
  },
  {
    path: '/hz',
    name: 'hz',
    components: {
      default: () => import(/* webpackChunkName: "base" */ '../views/hz/index.vue')
    }
  },
  {
    path: '/ft',
    name: 'ft',
    components: {
      default: () => import(/* webpackChunkName: "base" */ '../views/ft/index.vue')
    }
  },
  {
    path: '/acadmin',
    name: 'acadmin',
    components: {
      default: () => import(/* webpackChunkName: "base" */ '../views/acadmin/index.vue')
    }
  },
  {
    path: '/account',
    name: 'account',
    components: {
      default: () => import(/* webpackChunkName: "base" */ '../views/account/index.vue')
    }
  },
  {
    path: '/bindinguservipzh',
    name: 'bindinguservipzh',
    components: {
      default: () => import(/* webpackChunkName: "base" */ '../views/bindinguservipzh/index.vue')
    }
  },
  {
    path: '/registuservipzh',
    name: 'registuservipzh',
    components: {
      default: () => import(/* webpackChunkName: "base" */ '../views/registuservipzh/index.vue')
    }
  },
  {
    path: '/setpwd',
    name: 'setpwd',
    components: {
      default: () => import(/* webpackChunkName: "base" */ '../views/setpwd/index.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
