import CryptoJS from 'crypto-js'
export default {
  // 加密
  encrypt (word, keyStr) { // word, keyStr第一个参数是加密的字段名字  第二个是key值（16位）
    const myDate = new Date((new Date()).getTime() + 8 * 60 * 60 * 1000)
    const time = myDate.toJSON().split('-').join('').substr(0, 8) + myDate.toJSON().split('-').join('').substr(0, 8)
    keyStr = time
    // keyStr = keyStr || 'abcdefG123456789' // 密文（密钥）
    var key = CryptoJS.enc.Utf8.parse(keyStr)
    var srcs = CryptoJS.enc.Utf8.parse(word)
    var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.ZeroPadding })
    // return encrypted.toString()
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
  },

  // 解密
  decrypt (word, keyStr) {
    const myDate = new Date((new Date()).getTime() + 8 * 60 * 60 * 1000)
    const time = myDate.toJSON().split('-').join('').substr(0, 8) + myDate.toJSON().split('-').join('').substr(0, 8)
    keyStr = time
    var key = CryptoJS.enc.Utf8.parse(keyStr)// Latin1 w8m31+Yy/Nw6thPsMpO5fg==

    var decrypt = CryptoJS.ECB.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.ZeroPadding })
    return CryptoJS.enc.Utf8.stringify(decrypt).toString()
    // const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
    // return decryptedStr.toString()
  }
  // export const Decrypt = (word: any) => {
  //   let decrypt = CryptoJS.AES.decrypt(word, key, {
  //     mode: CryptoJS.mode.ECB,
  //     padding: CryptoJS.pad.ZeroPadding,
  //   });
  //   let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  //   return decryptedStr.toString();
  // };
//   encrypt (text) {
//     const iv = '0000000000000000'
//     const myDate = new Date((new Date()).getTime() + 8 * 60 * 60 * 1000)
//     const time = myDate.toJSON().split('-').join('').substr(0, 8) + myDate.toJSON().split('-').join('').substr(0, 8)
//     const key = time
//     return CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(key), {
//       iv: CryptoJS.enc.Utf8.parse(iv),
//       mode: CryptoJS.mode.CBC,
//       padding: CryptoJS.pad.NoPadding
//     }).toString()
//   },
//   // 解密
//   decrypt (text) {
//     const iv = '0000000000000000'
//     const myDate = new Date((new Date()).getTime() + 8 * 60 * 60 * 1000)
//     const time = myDate.toJSON().split('-').join('').substr(0, 8) + myDate.toJSON().split('-').join('').substr(0, 8)
//     const key = time
//     const decrypted = CryptoJS.AES.decrypt(text, CryptoJS.enc.Utf8.parse(key), {
//       iv: CryptoJS.enc.Utf8.parse(iv),
//       mode: CryptoJS.mode.CBC,
//       padding: CryptoJS.pad.NoPadding
//     })
//     console.log(CryptoJS.enc.Utf8)
//     return decrypted.toString(CryptoJS.enc.Utf8)
//   }
}
